var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-overlay',{attrs:{"show":_vm.loadingSubmit}},[_c('validation-observer',{ref:"formValidation"},[_c('b-form',{ref:"form",staticClass:"mt-50",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"4","xl":_vm.block ? 4 : 3}},[_c('FormLabel',{staticClass:"mb-75",attrs:{"label":"Judul","required":""}})],1),_c('b-col',{attrs:{"md":"8","xl":_vm.block ? 8 : 6}},[_c('validation-provider',{attrs:{"name":"Judul","rules":_vm.getRules(_vm.items.title)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:{ 'invalid': errors.length > 0 }},[_c('b-form-input',{attrs:{"type":_vm.items.title.type || 'text',"state":errors.length > 0 ? false:null},model:{value:(_vm.items.title.value),callback:function ($$v) {_vm.$set(_vm.items.title, "value", $$v)},expression:"items.title.value"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"4","xl":_vm.block ? 4 : 3}},[_c('FormLabel',{staticClass:"mb-75",attrs:{"label":"Key","required":""}})],1),_c('b-col',{attrs:{"md":"8","xl":_vm.block ? 8 : 6}},[_c('validation-provider',{attrs:{"name":"Key","rules":_vm.getRules(_vm.items.key)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:{ 'invalid': errors.length > 0 }},[_c('b-form-input',{attrs:{"type":_vm.items.key.type || 'text',"state":errors.length > 0 ? false:null},model:{value:(_vm.items.key.value),callback:function ($$v) {_vm.$set(_vm.items.key, "value", $$v)},expression:"items.key.value"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"4","xl":_vm.block ? 4 : 3}},[_c('FormLabel',{staticClass:"mb-75",attrs:{"label":"Tipe","required":""}})],1),_c('b-col',{attrs:{"md":"8","xl":_vm.block ? 8 : 6}},[_c('validation-provider',{attrs:{"name":"Tipe","rules":_vm.getRules(_vm.items.type)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Pilih","label":"text","options":_vm.items.type.options,"selectable":function (option) { return ! option.value.includes('select_value'); }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option[_vm.items.text || 'text'])+" ")]}}],null,true),model:{value:(_vm.items.type.selected),callback:function ($$v) {_vm.$set(_vm.items.type, "selected", $$v)},expression:"items.type.selected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.items.type.selected)?_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"4","xl":_vm.block ? 4 : 3}},[_c('FormLabel',{staticClass:"mb-75",attrs:{"label":"Nilai","required":""}})],1),_c('b-col',{attrs:{"md":"8","xl":_vm.block ? 8 : 6}},[_c('validation-provider',{attrs:{"name":"Nilai","rules":_vm.getRules(_vm.items.value)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.items.type.selected.value === 'switch')?_c('b-form-checkbox',{attrs:{"switch":"","inline":""},model:{value:(_vm.items.value.value),callback:function ($$v) {_vm.$set(_vm.items.value, "value", $$v)},expression:"items.value.value"}},[_vm._v(" "+_vm._s(_vm.items.text)+" ")]):(_vm.items.type.selected.value === 'number')?_c('b-input-group',{staticClass:"input-group-merge",class:{ 'invalid': errors.length > 0 }},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.items.value.value),callback:function ($$v) {_vm.$set(_vm.items.value, "value", $$v)},expression:"items.value.value"}})],1):(_vm.items.type.selected.value === 'text')?_c('b-input-group',{staticClass:"input-group-merge",class:{ 'invalid': errors.length > 0 }},[_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false:null},model:{value:(_vm.items.value.value),callback:function ($$v) {_vm.$set(_vm.items.value, "value", $$v)},expression:"items.value.value"}})],1):(_vm.items.type.selected.value === 'description')?_c('b-form-textarea',{attrs:{"rows":"3","state":errors.length > 0 ? false:null},model:{value:(_vm.items.value.value),callback:function ($$v) {_vm.$set(_vm.items.value, "value", $$v)},expression:"items.value.value"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2228167184)})],1)],1):_vm._e(),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"4","xl":_vm.block ? 4 : 3}},[_c('FormLabel',{staticClass:"mb-75",attrs:{"label":"Deskripsi"}})],1),_c('b-col',{attrs:{"md":"8","xl":_vm.block ? 8 : 6}},[_c('validation-provider',{attrs:{"name":"Deskripsi","rules":_vm.getRules(_vm.items.description)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":_vm.items.description.rows || 3,"state":errors.length > 0 ? false:null},model:{value:(_vm.items.description.value),callback:function ($$v) {_vm.$set(_vm.items.description, "value", $$v)},expression:"items.description.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"border-top pt-2"},[_c('b-form-row',{staticClass:"justify-content-end"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"variant":"flat-dark"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")])],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-dark.top",value:({ title: 'No changes', disabled: _vm.isSubmitAllowed }),expression:"{ title: 'No changes', disabled: isSubmitAllowed }",modifiers:{"v-dark":true,"top":true}}],staticClass:"d-flex align-items-center mb-75",attrs:{"disabled":_vm.loadingSubmit || !_vm.isSubmitAllowed,"type":"submit","variant":"primary"},on:{"click":function($event){return _vm.submit('stay')}}},[(_vm.loadingSubmit)?_c('b-spinner',{staticClass:"mr-50",attrs:{"small":""}}):_vm._e(),_c('span',[_vm._v("Simpan & Tambah Lainnya")])],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-dark.top",value:({ title: 'No changes', disabled: _vm.isSubmitAllowed }),expression:"{ title: 'No changes', disabled: isSubmitAllowed }",modifiers:{"v-dark":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"disabled":_vm.loadingSubmit || !_vm.isSubmitAllowed,"type":"submit","variant":"primary"},on:{"click":function($event){return _vm.submit('return')}}},[(_vm.loadingSubmit)?_c('b-spinner',{staticClass:"mr-50",attrs:{"small":""}}):_vm._e(),_c('span',[_vm._v("Simpan Pengaturan")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }